import ActionTypes from "./action-types";
import ContactUsService from "./../services/service";
import {
  addNotification,
  addErrorNotification,
} from "./../../../redux/system/system-action-creators";
let service = ContactUsService();

export const sendMessage = (data, resetForm) => (dispatch) => {
  dispatch(contacUsRequest());
  service
    .sendMessage(data)
    .then(() => {
      dispatch(addNotification("Your message has been successfully sent."));
      dispatch(contacUsSuccess());
      resetForm();
    })
    .catch((error) => {
      dispatch(addErrorNotification(error, "Unable to send your message."));
      dispatch(contacUsFailure());
    });
};

const contacUsRequest = () => ({
  type: ActionTypes.CONTACT_US_REQUEST,
});

const contacUsSuccess = () => ({
  type: ActionTypes.CONTACT_US_SUCCESS,
});

const contacUsFailure = () => ({
  type: ActionTypes.CONTACT_US_FAILURE,
});

export const sendQuote = (data, resetForm) => (dispatch) => {
  dispatch(quoteRequest());
  service
    .sendQuote(data)
    .then(() => {
      dispatch(
        addNotification("Your quotation request has been successfully sent.")
      );
      dispatch(quoteSuccess());
      resetForm();
    })
    .catch((error) => {
      dispatch(
        addErrorNotification(error, "Unable to send your quotation request.")
      );
      dispatch(quoteFailure());
    });
};

const quoteRequest = () => ({
  type: ActionTypes.CONTACT_US_QUOTE_REQUEST,
});

const quoteSuccess = () => ({
  type: ActionTypes.CONTACT_US_QUOTE_SUCCESS,
});

const quoteFailure = () => ({
  type: ActionTypes.CONTACT_US_QUOTE_FAILURE,
});

export const cleanUp = () => ({
  type: ActionTypes.CONTACT_US_CLEAN_UP,
});
