import React from "react";
import ContentSection from "components/common/content-section";
import Partners from "components/common/partners";
import "./partner.scss";

const Partner = () => {
  return (
    <div className="partner">
      <ContentSection>
        <div className="w-100 text-center">
          <Partners />
        </div>
      </ContentSection>
    </div>
  );
};

export default Partner;
