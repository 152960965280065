import React, { Component } from "react";
import { withRouter } from "react-router";
import ContentSection from "../../components/common/content-section";
import PageTagging from "components/common/page-tagging/page-tagging";
import ContentService from "../../services/content-service";

class ContentPage extends Component {
  getPageName = (pathName) => {
    return pathName
      .replace(new RegExp("^[/]+"), "")
      .replace(new RegExp("[/]+$"), "")
      .toLowerCase();
  };

  render() {
    const { location } = this.props;
    const pageName = this.getPageName(location.pathname);
    const page = ContentService().getContentPage(pageName);

    return (
      <React.Fragment>
        <PageTagging title={page.title} description={page.description} />
        {!page.noContentSection && (
          <ContentSection>{page.content}</ContentSection>
        )}
        {page.noContentSection && page.content}
      </React.Fragment>
    );
  }
}

export default withRouter(ContentPage);
