const interests = [
  {
    value: 0,
    label: "Workzone Traffic Management",
  },
  {
    value: 1,
    label: "Event Traffic Management",
  },
  {
    value: 2,
    label: "Traffic Planning",
  },
  {
    value: 3,
    label: "Equipment Hire",
  },
  {
    value: 4,
    label: "Other",
  },
];

export default interests;
