import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Main from "./main";
import { withFormik } from "formik";
import contactUsInitialValues from "./formik/formik/initial-values";
import contactUsValidationSchema from "./formik/formik/validation-schema";
import * as ActionCreators from "./../../redux/action-creators";

const EnhancedForm = withFormik({
  mapPropsToValues: () => contactUsInitialValues,
  validationSchema: contactUsValidationSchema,
  handleSubmit: (values, { props, resetForm }) => {
    props.sendMessage(values, resetForm);
  },
  displayName: "contactUsForm",
})(Main);

const mapStateToProps = (state) => state.contactUs;

export default withRouter(
  connect(mapStateToProps, { ...ActionCreators })(EnhancedForm)
);
