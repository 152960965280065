import { useEffect } from 'react';
import $ from 'jquery';

/**
 * This function closes the dropdown menu once one of the menu items was
*/
function useDropdownMenu() {
  useEffect(() => {
    const handleClick = (event) => {
      const dropdownDiv = $('.nav__dropdown.priority-nav__dropdown.show');
      
      if (dropdownDiv.length > 0 && !dropdownDiv.is(event.target)) {
        dropdownDiv.removeClass('show');
      }
    };

    $('a').on('click', handleClick);

    return () => {
      $('a').off('click', handleClick);
    };
  }, []);
}

export default useDropdownMenu;
