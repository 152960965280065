import { Link } from 'react-router-dom'
import React from 'react'

export const generateLinkTag = (link) => {
  const cursor = {
    cursor: "pointer"
  }

  if (typeof link.link === 'string' && link.link.includes('http')) {
    return (
      <a href={link.link} target="_blank" rel="noopener noreferrer" style={cursor}>
        {link.title}
      </a>
    )
  } else if (typeof link.link === 'function') {
    return (
      <a href="#" onClick={link.link} style={cursor}>
        {link.title}
      </a>
    )
  } else {
    return <Link to={link.link} style={cursor}>{link.title}</Link>
  }
}
