import React from "react";
import ImageHeader from "components/common/image-header";
import PurpleSection from "components/purple-section/purple-section";
import ContentSection from "components/common/content-section";
import TextTitle from "components/common/text-title";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <>
      <ImageHeader imgSrc={`/images/roadworks-4.jpg`} title="Our Services" />
      <PurpleSection text="Swift Traffic provides a range of services." />

      <ContentSection>
        <div className="w-100 text-center px-5">
          <TextTitle>
            <div className="title px-5">
              <h2>Our Services</h2>
            </div>
          </TextTitle>
        </div>
        <div className="row mt-3">
          <div className="col-md-6 text-center">
            <h4 className="mt-5">Traffic Management</h4>
            <img
              src="/images/roadworks-1.jpg"
              className="mt-4 mb-4 shadow"
              alt=""
            />
            <p className="font-weight-bold">
              Swift Traffic providing Traffic Management services Melbourne and
              other areas of Victoria.
            </p>
            <p>
              Swift Traffic employs fully accredited and trained traffic
              controllers equipped with fully stocked vehicles, available 24/7.
              We provide traffic management services across all areas of
              Victoria, handling all types of roads. Our staff adhere to and
              comply with National and VIC Standards, Austroads Guides, and all
              other relevant regulations.
            </p>
            <p className="mb-4">
              We are avaliable 7 days a week, 24 hours a day. Contact us today
              to find out how our experienced team can assist with all your
              Traffic Management requirements.
            </p>
            <div>
              <Link
                to="/contact-us/"
                className="sw-btn-primary btn btn-secondary"
              >
                Enquire Now
              </Link>
            </div>
          </div>
          <div className="col-md-6 text-center mb-5">
            <h4 className="mt-5">Traffic Planning and Permits</h4>
            <img
              src="/images/roadworks-5.jpg"
              className="mt-4 mb-4 shadow"
              alt=""
            />
            <p className="font-weight-bold">
              Swift Traffic providing Traffic Management Planning services for
              all road categories in Victoria.
            </p>
            <p>
              At Swift Traffic, we believe that exceptional road execution
              begins with a superior traffic plan. We offer the highest quality
              Traffic Guidance Schemes, meticulously designed to align with your
              specific needs and regulations.
            </p>
            <p className="mb-4">
              Our team liase with all necessary stakeholders including
              government departments, public transport operators, emergency
              services and other relevant bodies that require notification of
              your works. We ensure compliance and make the process simple.
            </p>
            <div>
              <Link
                to="/contact-us/"
                className="sw-btn-primary btn btn-secondary"
              >
                Enquire Now
              </Link>
            </div>
          </div>
        </div>
      </ContentSection>
    </>
  );
};

export default Services;
