import React from "react";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import ContentSection from "./../components/common/content-section";
import PurpleSection from "components/purple-section/purple-section";
import ImageHeader from "components/common/image-header";
import { corePublicPages } from "./public-content-core";
import TextTitle from "components/common/text-title";
import { Link } from "react-router-dom";

export const publicPages = [
  ...corePublicPages,
  {
    pageName: "careers",
    title: "Careers | Join the team | Swift Traffic",
    description:
      "Swift Traffic is a leader in the Traffic Management industry in South Australia and is always on the look out for new members to join our team.",
    noContentSection: true,
    content: (
      <>
        <ImageHeader imgSrc={`/images/roadworks-1.jpg`} title="Careers" />
        <PurpleSection text="Are you interested in working with us? Check out our job opportunities below." />
        <ContentSection>
          <div className="w-100 text-center px-5">
            <TextTitle>
              <div className="title px-5">
                <h2 className="mb-4">Current Opportunities</h2>
              </div>
            </TextTitle>
          </div>
          <div className="row mt-3 mb-5">
            <div className="col-md-12 text-center">
              <div className="text-center mt-5">
                <h4 className="mt-4">Traffic Controllers</h4>
                <p>
                  We are seeking Traffic Controllers to join the team at Swift
                  in Victoria. All relevant licences and tickets are required.
                </p>
                <a
                  href="https://swifttraffic.staffd.me/applicants/apply-now/traffic-controller-casual"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="sw-btn-primary btn btn-secondary mt-2 mb-4"
                >
                  Apply Now
                </a>
              </div>
            </div>
            {/* <div className="col-md-6 text-center">
              <h4 className="mt-5">Traffic Controller TMA Drivers</h4>
              <p>
                We are seeking Traffic Controllers with TMA driving experience.
                All relevant licences and tickets are required.
              </p>
              <a
                href="https://swifttraffic.staffd.me/applicants/apply-now/tma-driver"
                target="_blank"
                rel="noopener noreferrer"
                className="sw-btn-primary btn btn-secondary mt-2 mb-4"
              >
                Apply Now
              </a>
            </div> */}
          </div>
        </ContentSection>
        <ContentSection>
          <div className="w-100 text-center px-5">
            <TextTitle>
              <div className="title px-5">
                <h2>Contact us today!</h2>
              </div>
            </TextTitle>
          </div>
          <div className="text-center mt-4 mb-4">
            <Link
              to="/contact-us/"
              className="sw-btn-primary btn btn-secondary"
            >
              Enquire Now
            </Link>
          </div>
        </ContentSection>
      </>
    ),
  },
  {
    pageName: "gallery",
    title: "Photo Gallery | Swift Traffic",
    description: "Swift Traffic",
    noContentSection: true,
    content: (
      <>
        {/* <ImageHeader imgSrc={`/images/gallery.jpg`} title="Photo Gallery" /> */}
        <PurpleSection text="Our memories." />
        <ContentSection>
          <div className="w-100 text-center px-5">
            <TextTitle>
              <div className="title px-5">
                <h2>Photo Gallery</h2>
              </div>
            </TextTitle>
          </div>
          <div className="row mt-5 text-center">
            <div className="col-md-6 mb-3">
              <img src="/images/gallery/utes.jpg" className="shadow" alt="" />
            </div>
            <div className="col-md-6 mb-3">
              <img src="/images/gallery/event.jpg" className="shadow" alt="" />
            </div>
            <div className="col-md-6 mb-3">
              <img src="/images/gallery/tanks.jpg" className="shadow" alt="" />
            </div>
            <div className="col-md-6 mb-3">
              <img
                src="/images/gallery/high-tea-ccf.jpg"
                className="shadow"
                alt=""
              />
            </div>
            <div className="col-md-12 mb-3">
              <img src="/images/gallery/15.jpg" className="shadow" alt="" />
            </div>
            <div className="col-md-6 mb-3">
              <img src="/images/gallery/4.jpg" className="shadow" alt="" />
            </div>
          </div>
        </ContentSection>
      </>
    ),
  },
  {
    pageName: "new-page",
    title: "New Page",
    description: "",
    content: (
      <>
        <h1>New Page</h1>
      </>
    ),
  },
  {
    pageName: "new-page-2",
    title: "New Page",
    description: "",
    noContentSection: true,
    content: (
      <>
        <ContentSection>
          <h1>Section 1</h1>
        </ContentSection>
        <ContentSection bgClass="sw-bg-std-grey ">
          <h1>Section 2</h1>
        </ContentSection>
        <ContentSection>
          <h1>Section 3</h1>
        </ContentSection>
      </>
    ),
  },
];
