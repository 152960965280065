import React from "react";
import PropTypes from "prop-types";
import "./text-title.scss";

const TextTitle = ({ centered, children }) => {
  return (
    <div className={`text-title${centered ? " text-center" : ""}`}>
      <div className="d-flex justify-content-center">{children}</div>
      <div className="bottom-border"></div>
    </div>
  );
};

TextTitle.propTypes = {
  centered: PropTypes.bool,
  children: PropTypes.any,
};
TextTitle.defaultProps = {
  centered: true,
};

export default TextTitle;
