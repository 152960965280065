import React, { Component } from "react";
import View from "./view";
import { partners as PartnerItems } from "constants/partners";

class Partners extends Component {
  render() {
    return <View items={PartnerItems} />;
  }
}

export default Partners;
