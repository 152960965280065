import React from "react";
import "./image-header.scss";
import { PropTypes } from "prop-types";

const ImageHeader = ({ imgSrc, title, isHero, children }) => {
  return (
    <header className={`image-header${isHero ? "-hero" : ""}`}>
      <div
        style={{ backgroundImage: "url(" + imgSrc + ")" }}
        className="image-container d-flex flex-column justify-content-center align-items-center"
      ></div>

      {children ? (
        <div className="children-container w-100 d-flex flex-column justify-content-center align-items-center">
          {children}
        </div>
      ) : (
        <div className="text-container w-100 d-flex flex-column justify-content-center align-items-center">
          <h1>{title}</h1>
        </div>
      )}
    </header>
  );
};

ImageHeader.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  imgSrc: PropTypes.any.isRequired,
  isHero: PropTypes.bool,
};

export default ImageHeader;
