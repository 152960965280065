import React from "react";
import "./section.scss";

const ContentSection = ({
  bgColor,
  bgClass,
  maxWidth,
  padding,
  specificClass,
  children,
}) => {
  let defaultPadding = 4;
  let defaultColor = "";
  let defaultMaxWidth = 1140;

  const getContainerClass = () => {
    if (bgColor) return `container-bg-${bgColor}`;

    if (bgClass) return bgClass;

    if (defaultColor) return `container-bg-${defaultColor}`;

    return "";
  };

  const getContainerMaxWidth = () => {
    return `${maxWidth ? maxWidth : defaultMaxWidth}px`;
  };

  const definedPadding = padding ? padding : defaultPadding;
  const definedSpecificClass = specificClass ? ` ${specificClass}` : "";

  return (
    <div className={getContainerClass()}>
      <div
        className={`container-fluid py-${definedPadding} content-section${definedSpecificClass}`}
        style={{ maxWidth: getContainerMaxWidth() }}
      >
        <div className="row">
          <div className="col">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ContentSection;
