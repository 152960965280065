import { ActionTypes } from "./action-types";
import Service from "./service";

const service = Service();

export const loadPublicNews = () => (dispatch) => {
  dispatch(loadPublicNewsRequest());
  service
    .loadPublicNews()
    .then((data) => {
      dispatch(loadPublicNewsSuccess(data));
    })
    .catch(() => dispatch(loadPublicNewsFailure()));
};

const loadPublicNewsRequest = () => ({
  type: ActionTypes.PUBLIC_NEWS_LOAD_REQUEST,
});

const loadPublicNewsSuccess = (payload) => ({
  type: ActionTypes.PUBLIC_NEWS_LOAD_SUCCESS,
  payload,
});

const loadPublicNewsFailure = () => ({
  type: ActionTypes.PUBLIC_NEWS_LOAD_FAILURE,
});
