import React, { useEffect } from "react";
import CommonFastField from "components/common/common-fast-field";
import { Form } from "formik";
import { Row, Col, FormGroup } from "reactstrap";
import FormikSubmitButton from "components/common/formik-submit-button";

const ContactUsForm = ({ handleSubmit, resetForm, isLoading }) => {
  useEffect(() => {
    resetForm();
  }, []);

  return (
    <div className="py-4">
      <Row>
        <Col xs="12" md="9">
          <Form className="flex-fill mt-3" onSubmit={handleSubmit}>
            <Row>
              <Col xs="12" md="6">
                <CommonFastField
                  label="Name"
                  accessor="name"
                  showLabel
                  type="text"
                  overridePlaceholder={true}
                  showRequiredIndicator={true}
                />
              </Col>
            </Row>

            <Row>
              <Col xs="12" md="6">
                <CommonFastField
                  label="Email Address"
                  accessor="email"
                  showLabel
                  type="text"
                  overridePlaceholder={true}
                  showRequiredIndicator={true}
                />
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <CommonFastField
                  label="Phone"
                  accessor="phone"
                  showLabel
                  type="text"
                  overridePlaceholder={true}
                  showRequiredIndicator={false}
                />
              </Col>
            </Row>
            
            <Row>
              <Col xs="12">
                <CommonFastField
                  label="Message"
                  accessor="message"
                  showLabel
                  type="textarea"
                  overridePlaceholder={true}
                  showRequiredIndicator={true}
                />
              </Col>
            </Row>

            <FormGroup>
              <FormikSubmitButton
                label="Send"
                isLoading={isLoading}
                className="sw-btn-primary"
                scrollToFirstError={true}
              />
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default ContactUsForm;
