import React from "react";
import { Link } from "react-router-dom";
import ContentSection from "components/common/content-section";
import "./purple-section.scss";

const PurpleSection = ({ text, linkUrl, linkTitle, isBold }) => {
  return (
    <ContentSection bgClass="purple-section">
      <div className="text-center py-4">
        {isBold && <h2 className="text-white">{text}</h2>}
        {!isBold && <h3 className="text-white">{text}</h3>}
      </div>
      {linkTitle && (
        <div className="button-section">
          <Link to={linkUrl} className="sw-btn-section-feature">
            {linkTitle}
          </Link>
        </div>
      )}
    </ContentSection>
  );
};

export default PurpleSection;
