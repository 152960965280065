import React from "react";
import PropTypes from "prop-types";
import CollapsiblePanel from "../collapsible-panel/collapsible-panel";
import CollapsibleItem from "../collapsible-panel/collapsible-item";

const ContentCollapsiblePanel = ({
  sections,
  marginTop,
  marginBottom,
  openSection,
}) => {
  return (
    <div className={`mt-${marginTop} mb-${marginBottom}`}>
      <CollapsiblePanel sectionOpen={`section${openSection}`}>
        {({ handleInit, collapseState, handleToggle }) => {
          return sections.map((section, i) => (
            <div className="mb-3" key={i}>
              <CollapsibleItem
                id={`section${i}`}
                title={section.title}
                init={handleInit}
                collapseState={collapseState}
                toggle={handleToggle}
              >
                {section.content}
              </CollapsibleItem>
            </div>
          ));
        }}
      </CollapsiblePanel>
    </div>
  );
};

ContentCollapsiblePanel.propTypes = {
  sections: PropTypes.array.isRequired,
  marginTop: PropTypes.number.isRequired,
  marginBottom: PropTypes.number.isRequired,
  openSection: PropTypes.number.isRequired,
};

ContentCollapsiblePanel.defaultProps = {
  sections: [],
  marginTop: 3,
  marginBottom: 3,
  openSection: 0,
};

export default ContentCollapsiblePanel;
