import React from "react";
import { connect } from "react-redux";
import { Field } from "formik";
import { addNotification } from "./../../../redux/system/system-action-creators";
import ButtonFx from "./../button-fx";

const SubmitButton = ({
  label,
  isLoading,
  className,
  addNotification,
  scrollToFirstError,
  isCollapsibleForm,
}) => {
  return (
    <Field>
      {({ form }) => (
        <ButtonFx
          isLoading={isLoading}
          className={className}
          onClick={(event) => {
            event.preventDefault();
            form.validateForm().then((errors) => {
              let errorKeys = Object.keys(errors);

              if (errorKeys.length > 0) {
                addNotification(
                  "You have one or more fields missing, please correct these and submit again.",
                  "error"
                );

                if (scrollToFirstError) {
                  if (isCollapsibleForm) {
                    //Collapsible Form Handler
                    var firstErrorSection = document.getElementById(
                      errorKeys[0]
                    );
                    firstErrorSection.scrollIntoView();
                  } else {
                    //Normal Form Handler
                    let form = document.forms[document.forms.length - 1];
                    let marked = false;

                    Object.entries(form).forEach((x, i) => {
                      let existsInErrorList = errorKeys.includes(x[1].name);
                      if (existsInErrorList && !marked) {
                        document.forms[document.forms.length - 1][i].focus();
                        marked = true;
                      }
                    });
                  }
                }
              }

              form.handleSubmit();
            });
          }}
        >
          {label}
        </ButtonFx>
      )}
    </Field>
  );
};

const FormikSubmitButton = connect(null, { addNotification })(SubmitButton);

export default FormikSubmitButton;
