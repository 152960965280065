/**
 * ButtonFx
 * - Progress spinner feature
 * rcnet @Solvable 5.5.2019
 *
 * props exposed:
 *   - onClick = event onClick
 *   - isLoading = true or false (spinner hide/show)
 *   - name = button name
 *   - targetName = name of button to trigger loading spinner
 *   - color  = refer to reacstrap button colors
 *   - beatColor = beat color
 *   - className
 */

// Sample

/* <ButtonFx
  isLoading={isLoading}
  onClick={toggleLoad}
  beatColor={"red"}
  className={"acpan-link acpan-link-blue custom-button react-button"}
>
  Click Me!
</ButtonFx>; */

import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { css } from "@emotion/core";
import { BeatLoader } from "react-spinners";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  margin-top: 5px;
`;

const ButtonFx = (props) => {
  const loadSpinner =
    (props.targetName === props.name || props.targetName === "") &&
    props.isLoading;

  const {
    id,
    type,
    name,
    beatColor,
    icon,
    onMouseEnter,
    onMouseLeave,
    isLoading,
    disabled,
    onClick,
    block,
    className,
  } = props;

  return (
    <Button
      id={id}
      type={type}
      block={block}
      name={name}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      icon={icon}
      className={className}
      disabled={isLoading || disabled}
    >
      {loadSpinner ? (
        <BeatLoader
          css={override}
          sizeUnit={"px"}
          size={10}
          color={beatColor}
          loading={true}
        />
      ) : (
        <span>{props.children}</span>
      )}
    </Button>
  );
};

ButtonFx.propTypes = {
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  targetName: PropTypes.string,
  size: PropTypes.string,
  round: PropTypes.string,
  icon: PropTypes.string,
  beatColor: PropTypes.string,
};

ButtonFx.defaultProps = {
  name: "fxButton",
  targetName: "",
  isLoading: false,
  size: "",
  round: "true",
  icon: "false",
  beatColor: "#fff",
  className: "acpan-link acpan-link-green custom-button react-button",
};

export default ButtonFx;
