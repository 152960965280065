import React from "react";

const CardHeader2 = ({ title, icon, expandItem }) => {
  return (
    <h5 className="bg-light border-bottom p-3 mb-0 card-title">
      <div className="header-title-container">
        <div
          id="toggle"
          className={`toggle-icon mr-2 ${expandItem ? "toggle-now" : ""}`}
        >
          <i className={`fas fa-chevron-right text-muted`} />
        </div>
        <div>
          <i className={`${icon} mr-2`} />
          {title}
        </div>
      </div>
    </h5>
  );
};

const Header = ({ title, icon, expandItem, onClick }) => {
  return (
    <div className="header-section" onClick={onClick}>
      <CardHeader2 title={title} icon={icon} expandItem={expandItem} />
    </div>
  );
};

export default Header;
