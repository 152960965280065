/**
 * react-select + allOption
 *
 * Gives users of the fine component react-select the ability to select
 * all options with a single click.
 *
 * We're basically wrapping react-select but using a special option,
 * the "allOption". This option is exclusive of any other option
 * and it never hits the user's onChange() handler because it gets
 * replaced with the whole options list.
 *
 * There are many ways to implement this feature, but this one doesn't
 * affect the rest of your app, and it doesn't take any UI space.
 */
import React from "react";
import PropTypes from "prop-types";
import { default as ReactSelect } from "react-select";
import { FormGroup, Label } from "reactstrap";
import CustomErrorMessage from "./../error-message";
import { Field } from "formik";
import isNil from "lodash/isNil";

const getSelectComponent = (props, form, field) => {
  // const customStyles = {
  //   control: (base, state) => ({
  //     ...base,
  //     background: "#f5f5f7",
  //     boxShadow: state.isFocused ? null : null,
  //   }),
  //   menu: (base) => ({
  //     ...base,
  //     // override border radius to match the box
  //     borderRadius: 0,
  //     // kill the gap
  //     marginTop: 0,
  //   }),
  //   menuList: (base) => ({
  //     ...base,
  //     // kill the white space on first and last option
  //     padding: 0,
  //   }),
  // };

  if (props.allowSelectAll) {
    if (props.value.length === props.options.length) {
      return (
        <ReactSelect
          {...props}
          value={[props.allOption]}
          // styles={customStyles}
          onChange={(selected) => {
            if (isNil(selected)) {
              form.setFieldValue(props.accessor, []);
            } else {
              form.setFieldValue(props.accessor, selected.slice(1));
            }
          }}
          onBlur={() => form.setFieldTouched(props.accessor, true)}
        />
      );
    }

    return (
      <ReactSelect
        {...props}
        options={[props.allOption, ...props.options]}
        onChange={(selected) => {
          if (isNil(selected)) {
            form.setFieldValue(props.accessor, []);
          } else {
            if (selected.find((s) => s.value === "*")) {
              form.setFieldValue(props.accessor, props.options);
            } else {
              if (
                selected.length > 0 &&
                selected[selected.length - 1].value === props.allOption.value
              ) {
                form.setFieldValue(props.accessor, props.options);
              }
              form.setFieldValue(props.accessor, selected);
            }
          }
        }}
        // styles={customStyles}
        onBlur={() => form.setFieldTouched(props.accessor, true)}
      />
    );
  }

  return (
    <ReactSelect
      {...props}
      value={field.value}
      onChange={(selected) => {
        form.setFieldValue(props.accessor, isNil(selected) ? [] : selected);
      }}
      // styles={customStyles}
      onBlur={() => form.setFieldTouched(props.accessor, true)}
    />
  );
};

// specify props.allowSelectAll = true to enable!
const Select = (props) => {
  return (
    <FormGroup>
      {props.showLabel && (
        <Label className="font-weight-bold">{props.label}</Label>
      )}
      <Field name={props.accessor}>
        {({ meta, form, field }) => {
          return (
            <>
              {getSelectComponent(props, form, field)}
              <CustomErrorMessage error={meta.error} touched={meta.touched} />
            </>
          );
        }}
      </Field>
    </FormGroup>
  );
};

Select.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  label: PropTypes.string,
  showLabel: PropTypes.bool,
};

Select.defaultProps = {
  allOption: {
    label: "Select all",
    value: "*",
  },
};

export default Select;
