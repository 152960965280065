import React, { useState } from "react";
import jsxToString from "jsx-to-string";
import ContentCollapsiblePanel from "../../components/common/content-collapsible-panel";
import { faqs as FaqItems } from "../../constants/faqs";
import { withFormik, Form } from "formik";
import CommonField from "./../../components/common/common-field";
import UseMapFaqs from "./hooks/use-map-faqs";
import UseFilterFaqs from "./hooks/use-filter-faqs";
import EnhancedContentSection from "./../../components/common/enhanced-content-section";

const Faqs = ({ values }) => {
  let [mappedFaqs, setMappedFaqs] = useState([]);
  let [filteredFaqs, setFilteredFaqs] = useState([]);

  UseMapFaqs(FaqItems, setMappedFaqs, jsxToString);
  UseFilterFaqs(values.query, mappedFaqs, setFilteredFaqs);

  return (
    <>
      <EnhancedContentSection
        pageTitle="Frequently Asked Questions"
        isBlocking={false}
      >
        <section>
          <Form>
            <CommonField label="Search" accessor="query" type="text" />
          </Form>
        </section>

        <ContentCollapsiblePanel
          openSection={-1}
          sections={filteredFaqs.filter((faq) => faq.show)}
        />
      </EnhancedContentSection>
    </>
  );
};

const initialValues = { query: "" };

const formikEnhancer = withFormik({
  mapPropsToValues: () => initialValues,
  handleSubmit: (values, { props }) => {},
  displayName: "searchFaqsForm",
});

const enhancedFaqs = formikEnhancer(Faqs);

export default enhancedFaqs;
