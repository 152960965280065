import { useEffect } from "react";
import TextSearchLib from "./../../../core/utilities/textSearchLib";

const UseFilterFaqs = (query, mappedFaqs, setFilteredFaqs) => {
  const textSearchLib = TextSearchLib();
  useEffect(() => {
    let filtered = mappedFaqs.map((faq) => {
      return {
        ...faq,
        show:
          query === ""
            ? true
            : textSearchLib.search(query, faq.title) ||
              textSearchLib.search(query, faq.flattenedContent),
      };
    });
    setFilteredFaqs(filtered);
  }, [query, mappedFaqs]);
};

export default UseFilterFaqs;
