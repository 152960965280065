import React, { useRef } from 'react'
import 'priority-nav/dist/priority-nav-core.css'
import './styles.scss'
import { generateLinkTag } from './helpers'
import PropTypes from 'prop-types'
import useDropdownMenu from './hooks/useDropdownMenu'
import usePriorityNav from './hooks/usePriorityNav'

const PriorityNavBar = ({ links }) => {
  const navRef = useRef(null)

  usePriorityNav(navRef)
  useDropdownMenu();

  return (
    <div className="solvable-priority-nav">
      <nav ref={navRef}>
        <ul>
          {links.map((l, x) => (
            <li key={x}>{generateLinkTag(l)}</li>
          ))}
        </ul>
      </nav>
    </div>
  )
}

PriorityNavBar.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.any.isRequired,
    }),
  ).isRequired,
}

PriorityNavBar.defaultProps = {
  links: [],
}

export default PriorityNavBar
