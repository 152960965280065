import React, { useEffect } from "react";
import CommonFastField from "components/common/common-fast-field";
import { Form } from "formik";
import { Row, Col, FormGroup } from "reactstrap";
import FormikSubmitButton from "components/common/formik-submit-button";
import Select from "components/common/select";
import interests from "constants/interests";

const QuoteForm = ({ handleSubmit, resetForm, values, isQuoteSubmitting }) => {
  useEffect(() => {
    resetForm();
  }, []);

  return (
    <div className="py-4">
      <Row>
        <Col xs="12" md="9">
          <Form className="flex-fill mt-3" onSubmit={handleSubmit}>
            <Row>
              <Col xs="12" md="6">
                <CommonFastField
                  label="Name"
                  accessor="name"
                  showLabel
                  type="text"
                  overridePlaceholder={true}
                  showRequiredIndicator={true}
                />
              </Col>
            </Row>

            <Row>
              <Col xs="12" md="6">
                <CommonFastField
                  label="Phone Number"
                  accessor="phone"
                  showLabel
                  type="text"
                  overridePlaceholder={true}
                  showRequiredIndicator={true}
                />
              </Col>
            </Row>

            <Row>
              <Col xs="12">
                <CommonFastField
                  label="Email Address"
                  accessor="email"
                  showLabel
                  type="text"
                  overridePlaceholder={true}
                  showRequiredIndicator={true}
                />
              </Col>
            </Row>

            <Select
              label="I am interested in rates for"
              accessor="services"
              showLabel
              options={interests}
              isMulti
              allowSelectAll
              value={values.services}
              closeMenuOnSelect={false}
            />

<Row>
              <Col xs="12">
                <CommonFastField
                  label="Message"
                  accessor="message"
                  showLabel
                  type="textarea"
                  overridePlaceholder={true}
                  showRequiredIndicator={true}
                />
              </Col>
            </Row>

            <FormGroup>
              <FormikSubmitButton
                label="Submit"
                isLoading={isQuoteSubmitting}
                className="sw-btn-primary"
                scrollToFirstError={true}
              />
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default QuoteForm;
