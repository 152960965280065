import React from "react";
import ContentSection from "./../content-section";
import PageTagging from "components/common/page-tagging/page-tagging";
import BlockUiFx from "./../block-ui-fx";
import TextTitle from "../text-title";
import { isNil } from "lodash";

const EnhancedContentSection = ({
  pageTitle,
  isBlocking,
  bgColor,
  bgClass,
  maxWidth,
  padding,
  specificClass,
  children,
  showPageTitle,
  claim,
}) => {
  let allowAccess = isNil(claim) || claim;

  return (
    <>
      <BlockUiFx blocking={isBlocking} lowerZIndex={true}>
        {pageTitle && <PageTagging title={pageTitle} />}

        <ContentSection
          bgColor={bgColor}
          bgClass={bgClass}
          maxWidth={maxWidth}
          padding={padding}
          specificClass={specificClass}
        >
          <div className="my-5">
            {showPageTitle && pageTitle && (
              <div className="w-100 text-center">
                <TextTitle>
                  <h2>{pageTitle}</h2>
                </TextTitle>
              </div>
            )}

            {allowAccess && children}
          </div>
        </ContentSection>
      </BlockUiFx>
    </>
  );
};

export default EnhancedContentSection;

// import React from "react";
// import ContentSection from "./../content-section";
// import PageTitle from "./../page-title";
// import BlockUiFx from "./../block-ui-fx";
// import { useMediaQuery } from "react-responsive";

// const EnhancedContentSection = ({
//   pageTitle,
//   isBlocking,
//   showBackground,
//   backgroundClass,
//   isFullWidth,
//   mainContentClass,
//   children,
// }) => {
//   const isSmallDevice = useMediaQuery({
//     query: "(max-width: 750px)",
//   });

//   return (
//     <>
//       {pageTitle && <PageTitle title={pageTitle} />}

//       {showBackground && (
//         <ContentSection bgClass={backgroundClass} padding="0" />
//       )}

//       <>
//         <BlockUiFx blocking={isBlocking} lowerZIndex={true}>
//           {isSmallDevice ? (
//             <SmallScreenContent
//               pageTitle={pageTitle}
//               children={children}
//               isFullWidth={isFullWidth}
//               mainContentClass={mainContentClass}
//             />
//           ) : (
//             <BigScreenContent
//               pageTitle={pageTitle}
//               children={children}
//               isFullWidth={isFullWidth}
//               mainContentClass={mainContentClass}
//             />
//           )}
//         </BlockUiFx>
//       </>
//     </>
//   );
// };

// const BigScreenContent = ({
//   pageTitle,
//   children,
//   isFullWidth,
//   mainContentClass,
// }) => {
//   return (
//     <ContentSection
//       padding="0"
//       isFullWidth={isFullWidth}
//       bgClass={mainContentClass}
//     >
//       <div className="p-4">
//         <div className="my-4">
//           {pageTitle && <h2>{pageTitle}</h2>}
//           <div className="mt-4">{children}</div>
//         </div>
//       </div>
//     </ContentSection>
//   );
// };

// const SmallScreenContent = ({
//   pageTitle,
//   children,
//   isFullWidth,
//   mainContentClass,
// }) => {
//   return (
//     <ContentSection
//       padding="0"
//       isFullWidth={isFullWidth}
//       bgClass={mainContentClass}
//     >
//       <div className="mt-4">
//         {pageTitle && <h2>{pageTitle}</h2>}
//         <div className="mt-4">{children}</div>
//       </div>
//     </ContentSection>
//   );
// };

// export default EnhancedContentSection;
