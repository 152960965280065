import React from "react";
import PageTagging from "components/common/page-tagging/page-tagging";
import ImageHeader from "components/common/image-header";
import Features from "./components/features";
import ParnersHeader from "./components/partners-header";
import Partner from "./components/partner";
import "./home.scss";
import PurpleSection from "components/purple-section/purple-section";
import ContentSection from "components/common/content-section";
import TextTitle from "components/common/text-title";

const Home = () => {
  return (
    <div className="home">
      <ImageHeader imgSrc={`/images/home-2.jpg`} isHero>
        <div className="text-white d-flex flex-column justify-content-around align-items-center image-jumbotron">
          <div>
            <h1 className="text-uppercase font-weight-bold main-text">
              Swift Traffic
            </h1>
          </div>

          <div>
            <h1 className="sub-text text-uppercase">
              Serving Melbourne and Victoria
            </h1>
          </div>
        </div>
      </ImageHeader>

      <PurpleSection
        text="For all your Traffic Management needs!"
        linkUrl="/contact-us/"
        linkTitle="Contact Us Today!"
        isBold
      />

      <Features />

      <ContentSection>
        <div className="w-100 text-center px-5">
          <TextTitle>
            <div className="title px-5">
              <h2>About Us</h2>
            </div>
          </TextTitle>
        </div>

        <div className="row mt-4 mb-5">
          <div className="col-md-5">
            <h5 className="mt-4 mb-3">
              Swift Traffic Pty Ltd is an experienced traffic management company
              serving clients across multiple sectors. Our team of qualified
              professionals consistently delivers innovative solutions to
              Traffic Management challenges, ensuring safe execution,
              high-quality results, and cost-effective outcomes for our clients.
            </h5>
            <p>
              At Swift Traffic, safety is our top priority. Our experienced
              Operations team collaborates closely with clients to ensure
              complete compliance with all Traffic Management industry safety
              standards, and safety requirements on every worksite.
            </p>
            <div className="mt-4 text-center">
              <img
                src="/images/certifications.webp"
                className=""
                style={{ maxHeight: "150px" }}
                alt=""
              />
            </div>
          </div>
          <div className="col-md-7">
            <img src="/images/roadworks-4.jpg" className="mt-4 shadow" alt="" />
          </div>
        </div>
      </ContentSection>

      <ParnersHeader />

      <Partner />

      <PageTagging
        title="Swift Traffic | Traffic Management Melbourne | Traffic Planning"
        description="Provider of Traffic Management Services. Swift Traffic specialises in providing Traffic Management solutions and related services to Victoria."
      />
    </div>
  );
};

export default Home;
