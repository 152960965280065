const initialValues = {
  name: "",
  mobile: "",
  phone: "",
  email: "",
  services: [],
  message: "",

};

export default initialValues;
