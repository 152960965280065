import HttpClient from "core/http/httpClient";

const ContactUsService = () => {
  let httpClient = HttpClient();

  const sendMessage = (payload) => {
    let uri = "/contact";

    return httpClient.post(uri, {
      ...payload,
    });
  };

  const sendQuote = (payload) => {
    let uri = "/get-an-estimate";

    let data = {
      name: payload.name,
      email: payload.email,
      phone: payload.phone,
      message: payload.message,
      services: payload.services.map((x) => x.label).join(", "),
    };

    return httpClient.post(uri, data);
  };

  return {
    sendMessage,
    sendQuote,
  };
};

export default ContactUsService;
