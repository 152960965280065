const TextSearchLib = () => {
  const search = (filterText, textToSearch) => {
    let regexString = "";

    // if the start and end char is a ", match entire string
    if (
      filterText.length > 1 &&
      filterText[0] === '"' &&
      filterText[filterText.length - 1] === '"'
    ) {
      regexString = `\\b${filterText.replace(/"/gi, "")}\\b`;
    } else {
      filterText = filterText.replace('"', "");

      const words = filterText
        .split(/\s+/g)
        .map(s => s.trim())
        .filter(s => !!s);
      const hasTrailingSpace = filterText.endsWith(" ");

      regexString = words
        .map((word, i) => {
          if (i + 1 === words.length && !hasTrailingSpace) {
            // The last word - ok with the word being "startswith"-like
            return `(?=.*\\b${_escapeRegExp(word)})`;
          } else {
            // Not the last word - expect the whole word exactly
            return `(?=.*\\b${_escapeRegExp(word)}\\b)`;
          }
        })
        .join("");
    }

    const searchRegex = new RegExp(regexString, "gi");

    return searchRegex.test(textToSearch);
  };

  const _escapeRegExp = s => {
    return s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
  };

  return {
    search
  };
};

export default TextSearchLib;
