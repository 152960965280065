import React from "react";
import PageTagging from "components/common/page-tagging/page-tagging";
import ImageHeader from "components/common/image-header";
import PurpleSection from "components/purple-section/purple-section";
import ContentSection from "components/common/content-section";
import TextTitle from "components/common/text-title";

const AboutUs = () => {
  return (
    <>
      <ImageHeader imgSrc={`/images/roadworks-4.jpg`} title="About Us" />
      <PurpleSection text="About Swift Traffic." />
      <ContentSection>
        <div className="w-100 text-center px-5">
          <TextTitle>
            <div className="title px-5">
              <h2>About Us</h2>
            </div>
          </TextTitle>
        </div>

        <div className="row mt-4">
          <div className="col-md-5">
            <h5 className="mt-4 mb-3">Swift Traffic Pty Ltd</h5>
            <p>XX</p>
          </div>
          <div className="col-md-7">
            <img src="/images/roadworks-3.jpg" className="mt-4 shadow" alt="" />
          </div>
        </div>
      </ContentSection>

      <ContentSection bgClass="sw-bg-std-grey py-5 d-flex flex-row justify-content-center features">
        <div className="w-100 text-center px-5">
          <TextTitle>
            <div className="title px-5">
              <h2>Our Team</h2>
            </div>
          </TextTitle>
        </div>
        <div className="row mt-4 text-center">
          <div className="col-md-6">
            <h5 className="mt-4">XX</h5>
            <p>XX</p>
          </div>
          <div className="col-md-6">
            <h5 className="mt-4">XX</h5>
            <p>XX</p>
          </div>
        </div>
      </ContentSection>
      <ContentSection bgClass="py-5 d-flex flex-row justify-content-center features">
        <div className="w-100 text-center px-5">
          <TextTitle>
            <div className="title px-5">
              <h2>Section Heading</h2>
            </div>
          </TextTitle>
        </div>
        {/* <div className="row mt-4 text-center">
          <div className="col-md-6">
            <h5 className="mt-5">Consultation</h5>
            <p>
              We gain an in-depth understanding of the project requirements and
              recommend a solution.
            </p>
          </div>
          <div className="col-md-6">
            <h5 className="mt-5">Cost Estimation</h5>
            <p>We will generate a cost estimate based on our consultation.</p>
          </div>
          <div className="col-md-6">
            <h5 className="mt-5">Planning</h5>
            <p>
              We liaise with you to plan and schedule the works, ensuring there
              are no delays on-site. Then we will submit plans to necessary
              authorities and arrange permits.
            </p>
          </div>
          <div className="col-md-6">
            <h5 className="mt-5">Completion</h5>
            <p>
              Our Traffic Controllers will implement the plan onsite and will
              complete all relevant documents pertaining to your work onsite
              then our Administration team will submit an invoice for payment.
            </p>
          </div>
        </div>
        <div className="row mt-5 text-center">
          <div className="col-md-12">
            <h5>
              We are proud members of the Traffic Management Association of
              Australia
            </h5>
          </div>
          <div className="col-md-3 rounded mx-auto mt-5">
            <a
              href="https://tmaa.asn.au/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://solvable.azureedge.net/swift-traffic/tmaa-logo.jpg"
                className=""
                alt=""
              />
            </a>
          </div>
        </div> */}
      </ContentSection>

      <PageTagging
        title="About Us | Traffic Management Industry Leader South Australia"
        description=""
      />
    </>
  );
};

export default AboutUs;
