import React from "react";
import "./footer.scss";
import { Row, Col, Container } from "reactstrap";

const Footer = () => {
  return (
    <footer className="w-100 pt-3 pb-5 d-flex flex-column shadow-md">
      <Container
        fluid
        className="container d-flex flex-column w-100 justify-content-center align-items-center mb-3"
      >
        <Row className="w-100 justify-content-center" form noGutters="true">
          <Col
            md="4"
            className="item d-flex justify-content-center justify-content-md-start"
          >
            <img
              className="img-fluid"
              src="/images/swift-traffic-logo.png"
              alt="Swift Traffic Pty Ltd"
              width="70%"
            />
          </Col>

          <Col md="4" className="item">
            <p className="text-uppercase font-weight-bold">Office</p>
            <p>
              <i className="fas fa-map-marker-alt mr-2" /> 42/49 McArthurs Rd,
              Altona North, 3025
            </p>
            <p>
              <i className="fas fa-at mr-1" />
              <a href="mailto:bookings@swifttraffic.com.au" className="ml-1">
                bookings@swifttraffic.com.au
              </a>
            </p>
          </Col>
          <Col md="4" className="item">
            <p className="text-uppercase font-weight-bold">Contact Details</p>
            <p>
              <i className="fas fa-mobile-alt mr-3" />
              <a href="tel:1300 00 22 60">1300 00 22 60</a>
            </p>
            <p>
              <i className="fas fa-at mr-2" />
              <a href="mailto:bookings@swifttraffic.com.au" className="ml-1">
                bookings@swifttraffic.com.au
              </a>
            </p>
            {/* <p>
              <i className="fas fa-mobile-alt mr-3" />
              <a href="tel:+61 401 868 142">Stefan - 0401 868 142</a>
            </p>
            <p>
              <i className="fas fa-at mr-2" />
              <a
                href="mailto@swifttraffic.com.au"
                className="ml-1"
              >
                @swifttraffic.com.au
              </a>
            </p> */}
          </Col>
        </Row>
      </Container>

      <div className="text-white w-100 footnote py-2 shadow-lg">
        <div className="col text-center">
          &copy; {new Date().getFullYear()} Swift Traffic Pty Ltd. All Rights
          Reserved.{" "}
          {/* <a
            href="/pdfs/Swift Traffic-privacy-policy.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Swift Traffic Privacy Policy.{" "}
          </a> */}
          - Website by{" "}
          <a
            href="https://www.solvable.com.au"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-item-style"
            title="Solvable provides business efficiency &amp; client value through creating cost effective, innovative solutions utilising cutting edge technologies, designed with a philosophy that &ldquo;Anything is Possible, Everything is Solvable&rdquo;."
          >
            Solvable.
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
