import React from "react";
import { Card } from "reactstrap";
import "./card-item.scss";

const CardItem = ({ children }) => {
  return (
    <Card body className="card-item shadow text-center text-content">
      {children}
    </Card>
  );
};

export default CardItem;
