import HttpClient from "core/http/httpClient";

const Service = () => {
  let httpClient = HttpClient();
  let uri = "/v1/news/public/all";

  const loadPublicNews = () => httpClient.get(uri).then(({ data }) => data);

  return {
    loadPublicNews,
  };
};

export default Service;
