import { useEffect } from 'react'
import priorityNav from 'priority-nav'

function usePriorityNav(navRef) {
  useEffect(() => {
    if (navRef.current) {
      priorityNav.init()
    }
  }, [navRef])
}

export default usePriorityNav
