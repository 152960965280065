import Playground from "views/playground";
import Home from "views/home";
import Faqs from "../views/faqs";
import ContactUs from "views/contact-us";
import ContentPage from "views/content-page";
import Services from "views/services";
import AboutUs from "views/about-us";

const ModuleRoutes = [
  {
    path: "/playground/:path",
    name: "Playground",
    component: Playground,
    privateRoute: false,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    privateRoute: false,
  },
  {
    path: "/about/",
    name: "About",
    component: AboutUs,
    privateRoute: false,
  },
  {
    path: "/faqs/",
    name: "FAQs",
    component: Faqs,
    privateRoute: false,
  },
  {
    path: "/contact-us/",
    name: "Contact Us",
    component: ContactUs,
    privateRoute: false,
  },
  {
    path: "/estimate/",
    name: "Quote",
    component: ContactUs,
    privateRoute: false,
  },
  {
    path: "/services/",
    name: "Services",
    component: Services,
    privateRoute: false,
  },
  {
    path: "*",
    component: ContentPage,
    privateRoute: false,
  },
];

export default ModuleRoutes;
