import React from "react";
//import { Link } from "react-router-dom";
//import ContentSection from "../components/common/content-section";

export const corePublicPages = [
  {
    pageName: "get-in-touch",
    title: "Contact Us",
    description: "",
    content: <></>,
  },
  {
    pageName: "privacy-policy",
    title: "Privacy Policy",
    description: "",
    content: <></>,
  },
  {
    pageName: "not-found",
    title: "Page Not Found",
    description: "",
    content: (
      <>
        <h1>Page Not Found</h1>
      </>
    ),
  },
];
