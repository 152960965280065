import { useEffect } from "react";

const UseMapFaqs = (FaqItems, setMappedFaqs, jsxToString) => {
  useEffect(() => {
    let mapped = FaqItems.map((faq) => {
      return {
        ...faq,
        flattenedContent: jsxToString(faq.content).replace(/(<([^>]+)>)/gi, ""),
      };
    });

    setMappedFaqs(mapped);
  }, []);
};

export default UseMapFaqs;
