import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Name must have at least 2 characters.')
    .max(50, 'Name is too long.')
    .required('Name is required.'),
  phone: Yup.number()
    .min(10, 'Invalid phone number.')
    .required('Phone is required.')
    .typeError('Invalid phone number.'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email address is required.'),
  services: Yup.array().required('Please choose at least one option'),
  message: Yup.string()
    .required('Message is required.')
    .matches(/^\s*([^\s]+\s*){1,500}$/, 'Message should not exceed 500 Words.'),
})

export default validationSchema
